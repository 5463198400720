import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            Money manager,
          </h1>
          <h1 className="primary-heading">
            Expense tracker &
          </h1>
          <h1 className="primary-heading">
            Budget planner
          </h1>
          <p className="primary-text">
            Soldi is a free money manager app. Keep track of your expenses and plan your budgets all in one place.
          </p>
          <div className="about-buttons-container">
            <a 
              href="itms-apps://apps.apple.com/es/app/soldi-control-gastos-finanzas/id6739777905" 
              className="secondary-button"
              rel="noopener noreferrer"
            >
              Download for iOS
            </a>
            {/* <button className="secondary-button">Download for Android</button> */}
          </div>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;