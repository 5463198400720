import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        {/* <p className="primary-subheading">About</p> */}
        <h1 className="primary-heading">
        Keep track of your money
        </h1>

        <p className="primary-text">
        Designed to help you track expenses, create personalized budgets, and manage your income effortlessly.        </p>
        {/* <div className="about-buttons-container">
          <button className="secondary-button">Download for iOS</button>
          <button className="secondary-button">Download for Android</button>
          <button className="watch-video-button">
            <BsFillPlayCircleFill /> Download for Android
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default About;
