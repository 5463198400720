import React from "react";
import Logo from "../Assets/Logo.svg";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { SiTiktok } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { BsReddit } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          <a href="https://www.instagram.com/soldi_______/" target="_blank" rel="noopener noreferrer">
            <BsInstagram />
          </a>
          <a href="https://www.tiktok.com/@soldi.so" target="_blank" rel="noopener noreferrer">
            <SiTiktok />
          </a>
          {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <BsTwitter />
          </a> */}
          {/* <a href="https://reddit.com" target="_blank" rel="noopener noreferrer">
            <BsReddit />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a> */}
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <a href="https://ritzy-spy-356.notion.site/T-rminos-y-condiciones-beacf7516994417dbfef8ff8c1547c86" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <span>Terms & Conditions</span>
          </a>
          <a href="https://ritzy-spy-356.notion.site/Pol-tica-de-Privacidad-6637907706f54b4fa320ae4f5d89819d?pvs=74" target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none' }}>
          <span>Privacy Policy</span>
          </a>
          <a href="https://mail.google.com/mail/u/0/#inbox?compose=new" target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none' }}>
          <span>soldifounders@gmail.com</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
